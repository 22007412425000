import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import ScrollableContent from 'components/scrollable-content'
import Button from 'components/button'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <Layout seo={{ title: 'Page Not Found' }}>
      <ScrollableContent>
        <h2>Page Not Found</h2>
        <p>The page you're looking for doesn't exist or has been removed.</p>
        <Button as={StyledLink} to='/' ghost>
          Return home
        </Button>
      </ScrollableContent>
    </Layout>
  )
}

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export default NotFoundPage
