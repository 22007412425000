import React from 'react'
import styled from 'styled-components'
import Content from './content'

const ScrollableContent = ({ children, ...props }) => {
  return <StyledContent {...props}>{children}</StyledContent>
}

const StyledContent = styled(Content)`
  height: 100%;
  overflow-y: auto;

  @media screen and (max-width: 899px) {
    overflow-y: visible;
  }

  @media print {
    overflow-y: visible;
  }
`

export default ScrollableContent
